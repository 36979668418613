<template>
  <v-dialog
    v-model="show"
    max-width="500px"
  >
    <v-card>
      <v-toolbar
        flat
        dense
        color="grey lighten-2"
      >
        <v-toolbar-title>
          Alterar senha
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            icon
            small
            @click="show = false"
          >
            <v-icon
              v-text="'mdi-close'"
            />
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-4 text-center">
        <validation-observer ref="observer">
          <v-row>
            <v-col>
              <validation-observer ref="observer">
                <validation-provider
                  v-slot="{ errors }"
                  vid="password"
                >
                  <v-text-field
                    v-model="newPassword"
                    :disabled="loadingSave"
                    label="Nova senha"
                    type="password"
                    :error-messages="errors"
                  />
                </validation-provider>
              </validation-observer>
            </v-col>
          </v-row>
        </validation-observer>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="success"
          outlined
          :loading="loadingSave"
          @click="save"
        >
          <v-icon
            v-text="'mdi-content-save'"
            left
          />
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import usersApi from '@/api/users'

  export default {

    props: {
      value: {
        type: Boolean,
        default: false,
      },
      userId: {
        type: Number,
        default: 0,
      },
    },

    data () {
      return {
        loading: false,
        loadingSave: false,
        newPassword: null,
      }
    },

    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },



    methods: {


      async save () {
        try {
          this.loadingSave = true
          this.$refs.observer.setErrors({})

          await usersApi.changePassword(this.userId, {
            password: this.newPassword,
          })

          this.show = false
          this.$snackbar.show({ color: 'success', message: this.$messages._('success_change_password') })
          this.newPassword = null
        } catch (e) {
          if (e.response.status === 422) {
            this.$refs.observer.setErrors(this.$apiError.fieldsValidation(e))
            return
          }

          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingSave = false
        }
      },

    },

  }
</script>
